<template>
  <v-card>
    <v-card-text>
      <!-- Description -->
      <!-- <h1>ใบสั่งซื้อ</h1> -->
      <div class="d-flex justify-space-between test2">
        <!-- content left -->
        <div class="test">
          <!-- ถึงกำหนดรับสินค้า -->
          <div>
            <p class="mb-0 text-center font-weight-bold">บริษัทที่มียอดซื้อสูง</p>
            <div class="me-2 box_content box-1">
              <p class="mb-0">ทั้งหมด</p>
              <div class="d-flex justify-center">
                <p class="mb-0 me-1">
                  {{
                    this.$store.state.draft.amount +
                      this.$store.state.notpaid.amount +
                      this.$store.state.paid.amount +
                      this.$store.state.makeSend.amount +
                      this.$store.state.send.amount +
                      this.$store.state.expire.amount +
                      this.$store.state.cancel.amount
                  }}
                </p>
                <p class="mb-0">รายการ</p>
              </div>
            </div>
          </div>
          <!-- รับสินค้าภายใน 7 วัน -->
          <div>
            <p class="mb-0 text-center font-weight-bold">บริษัทที่มียอดซื้อปานกลาง</p>
            <div class="me-2 box_content box-2">
              <p class="mb-0">ทั้งหมด</p>
              <div class="d-flex justify-center">
                <p class="mb-0 me-1">{{ this.$store.state.draft.amount }}</p>
                <p class="mb-0">รายการ</p>
              </div>
            </div>
          </div>
          <!-- รับสินค้ามากกว่า 7 วัน -->
          <div>
            <p class="mb-0 text-center font-weight-bold">บริษัทที่มียอดซื้อต่ำ</p>
            <div class="me-2 box_content box-3">
              <p class="mb-0">ทั้งหมด</p>
              <div class="d-flex justify-center">
                <p class="mb-0 me-1">{{ this.$store.state.notpaid.amount }}</p>
                <p class="mb-0">รายการ</p>
              </div>
            </div>
          </div>
        </div>
        <!-- content right -->
        <div class="content-header-right">
          <v-dialog transition="dialog-top-transition" max-width="800">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on">สร้างซัพพลายเออร์</v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="primary">สร้างซัพพลายเออร์</v-toolbar>
                <CreateData />
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          <v-dialog transition="dialog-top-transition" max-width="800">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on">อัพโหลดซัพพลายเออร์</v-btn>
            </template>
            <template>
              <v-card>
                <v-toolbar color="primary">อัพโหลดซัพพลายเออร์</v-toolbar>
                <ModalData />
              </v-card>
            </template>
          </v-dialog>
        </div>
      </div>
      <v-row class="mt-5">
        <v-col md="4" sm="6" cols="12">
          <v-text-field
            :prepend-inner-icon="icons.mdiMagnify"
            label="ค้นหา"
            type="text"
            outlined
            dense
            hide-details
            placeholder="ค้นหา"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="6" cols="12">
          <v-select
            :prepend-inner-icon="icons.mdiFilter"
            dense
            outlined
            hide-details
            label="ตัวกรอง"
            :items="filter"
          ></v-select>
        </v-col>
        <v-col md="4" sm="6" cols="12">
          <v-select
            :prepend-inner-icon="icons.mdiFileDocument"
            dense
            hide-details
            outlined
            label="เลขที่"
            :items="filterBill"
          ></v-select>
        </v-col>
      </v-row>
      <!-- ตารางสถานะต่าง ๆ -->
      <v-data-table
        v-model="selected"
        :search="search"
        :headers="headers"
        :items="desserts"
        :single-select="singleSelect"
        item-key="name"
        show-select
        class="elevation-1 mt-5"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiCartMinus,
  mdiMagnify,
  mdiTruck,
  mdiPrinterSearch,
  mdiPlusCircle,
  mdiFilter,
  mdiFileDocument,
} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import CreateData from './modalContentSupplier/SupplierCreateData.vue'
import ModalData from './modalContentSupplier/UploadModal.vue'

export default {
  components: { DatePicker, CreateData, ModalData },
  setup() {
    const filter = ['แบบร่าง', 'อนุมัติ', 'ยืนยันสั่งซื้อ', 'ยกเลิก', 'รับสินค้าบางส่วน', 'รับสินค้าครบแล้ว']
    const filterBill = ['เลขที่', 'Supplier']
    const itemSupplier = [{ title: 'อัพโหลดรูป' }]
    // { title: 'เลือกจากอัลบั้ม' }

    return {
      icons: {
        mdiCartMinus,
        mdiMagnify,
        mdiTruck,
        mdiPrinterSearch,
        mdiPlusCircle,
        mdiFilter,
        mdiFileDocument,
      },
      filter,
      filterBill,
      itemSupplier,
    }
  },
  data() {
    return {
      time1: null,
      time2: null,
      time3: null,
      dataOpenUser: false,
      selected: [],
      headers: [
        {
          text: 'Supplier',
          align: 'start',
          sortable: false,
          value: 'supplier',
        },
        { text: 'สถานะ', value: 'status' },
        { text: 'เลขประจำตัวผู้เสียภาษี', value: 'numberTax' },
        { text: 'Supplier', value: 'nameSupplier' },
        { text: 'เครดิต', value: 'cradit' },
        { text: 'วันที่วางบิล', value: 'timeBill' },
        { text: 'วันที่รับเงิน', value: 'timeMoney' },
      ],
      desserts: [
        {
          id: 'wyw1',
          supplier: 'SP-00000001',
          status: 'เปิดใช้งาน',
          numberTax: 1234567890987,
          nameSupplier: 'บจก. A จำกัด',
          cradit: 200000,
          timeBill: '28/7/2022 15:00',
          timeMoney: '2/8/2022 18:00',
        },
        {
          id: 'wyw2',
          supplier: 'SP-00000001',
          status: 'เปิดใช้งาน',
          numberTax: 1234567890987,
          nameSupplier: 'บจก. B จำกัด',
          cradit: 200000,
          timeBill: '28/7/2022 15:00',
          timeMoney: '2/8/2022 18:00',
        },
        {
          id: 'wyw1',
          supplier: 'SP-00000001',
          status: 'เปิดใช้งาน',
          numberTax: 1234567890987,
          nameSupplier: 'บจก. C จำกัด',
          cradit: 200000,
          timeBill: '28/7/2022 15:00',
          timeMoney: '2/8/2022 18:00',
        },
        {
          id: 'wyw2',
          supplier: 'SP-00000001',
          status: 'เปิดใช้งาน',
          numberTax: 1234567890987,
          nameSupplier: 'บจก. D จำกัด',
          cradit: 200000,
          timeBill: '28/7/2022 15:00',
          timeMoney: '2/8/2022 18:00',
        },
        {
          id: 'wyw1',
          supplier: 'SP-00000001',
          status: 'เปิดใช้งาน',
          numberTax: 1234567890987,
          nameSupplier: 'บจก. E จำกัด',
          cradit: 200000,
          timeBill: '28/7/2022 15:00',
          timeMoney: '2/8/2022 18:00',
        },
        {
          id: 'wyw2',
          supplier: 'SP-00000001',
          status: 'เปิดใช้งาน',
          numberTax: 1234567890987,
          nameSupplier: 'บจก. F จำกัด',
          cradit: 200000,
          timeBill: '28/7/2022 15:00',
          timeMoney: '2/8/2022 18:00',
        },
        {
          id: 'wyw2',
          supplier: 'SP-00000001',
          status: 'เปิดใช้งาน',
          numberTax: 1234567890987,
          nameSupplier: 'บจก. G จำกัด',
          cradit: 200000,
          timeBill: '28/7/2022 15:00',
          timeMoney: '2/8/2022 18:00',
        },
        {
          id: 'wyw2',
          supplier: 'SP-00000001',
          status: 'เปิดใช้งาน',
          numberTax: 1234567890987,
          nameSupplier: 'บจก. H จำกัด',
          cradit: 200000,
          timeBill: '28/7/2022 15:00',
          timeMoney: '2/8/2022 18:00',
        },
        {
          id: 'wyw1',
          supplier: 'SP-00000001',
          status: 'เปิดใช้งาน',
          numberTax: 1234567890987,
          nameSupplier: 'บจก. I จำกัด',
          cradit: 200000,
          timeBill: '28/7/2022 15:00',
          timeMoney: '2/8/2022 18:00',
        },
        {
          id: 'wyw2',
          supplier: 'SP-00000001',
          status: 'เปิดใช้งาน',
          numberTax: 1234567890987,
          nameSupplier: 'บจก. J จำกัด',
          cradit: 200000,
          timeBill: '28/7/2022 15:00',
          timeMoney: '2/8/2022 18:00',
        },
      ],
    }
  },
  methods: {
    switchOpenUser() {
      this.dataOpenUser = !this.dataOpenUser
    },
  },
}
</script>

<style scoped>
.box_content {
  text-align: center;
  background-color: #f4f5f9;
  display: grid;
  align-items: center;
  padding: 10px 0;
  color: #8e8a98;
  font-weight: 600;
  font-size: 16px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.box_content.box-1 {
  background-color: #8dbc89;
  color: #ffffff;
  width: 21rem;
  height: 5rem;
}
.box_content.box-2 {
  background-color: dodgerblue;
  color: #ffffff;
  width: 10rem;
  height: 5rem;
}
.box_content.box-3 {
  color: #ffffff;
  background-color: coral;
  width: 10rem;
  height: 5rem;
}
.bg-header {
  background-color: #8dbc89;
}
.bg-header p {
  color: #f4f5f9;
  font-weight: 600;
  font-size: 16px;
}
.bg-header span {
  color: #fff8ff;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  text-decoration: underline;
}
.checkItem {
  width: 15px;
  height: 15px;
  margin-right: 1rem;
}
.picker-res {
  width: 100% !important;
}
.test {
  display: flex;
}
.content-header-right {
  display: grid;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .test {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  .test2 {
    display: grid !important;
  }
  .mg-res {
    margin-top: 1.5rem;
    transform: translateX(-40%);
    left: 50%;
  }
  /* .picker-res {
    width: 100% !important;
  } */
  .box_content.box-1 {
    width: 5rem !important;
  }
  .box_content.box-2 {
    width: 5rem !important;
  }
  .box_content.box-3 {
    width: 5rem !important;
  }
}

@media screen and (max-width: 960px) {
  .box_content.box-1 {
    width: 8rem;
  }
  .box_content.box-2 {
    width: 8rem;
  }
  .box_content.box-3 {
    width: 8rem;
  }
}
</style>
