<template>
  <v-card-text class="mt-4">
    <!-- Header -->
    <v-row>
      <v-col md="6" sm="6" cols="12">
        <h3>ซัพพลายเออร์</h3>
        <p class="mb-0">เพิ่มรายละเอียดของซัพพลายเออร์ <a href="#">เรียนรู้เพิ่มเติม</a></p>
      </v-col>
      <v-col md="6" sm="6" cols="12" class="d-flex justify-end">
        <v-dialog transition="dialog-top-transition" width="700">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" text v-bind="attrs" v-on="on" class="me-2">
              <v-icon>{{ icons.mdiMagnify }}</v-icon>
              ข้อมูลกรมสรรพากร
            </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary">
                <p class="mb-0">ค้นหาข้อมุลบริษัทจากฐานข้อมูลกรมสรรพากร</p>
              </v-toolbar>
              <RevenueDepartment />
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>

      <!-- content supplier -->
      <!-- ซัพพลายเออร์ -->
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1 d-flex align-center justify-end">
        <h4>ประเภทซัพพลายเออร์</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-0">
        <v-radio-group v-model="radioGroup">
          <v-radio v-for="n in dataTypeSupplier" :key="n" :label="`${n}`" :value="n"></v-radio>
        </v-radio-group>
      </v-col>
      <!-- ชื่อซัพพลายเออร์ -->
      <v-col cols="12" sm="6" md="4">
        <h4 class="text-end">ชื่อซัพพลายเออร์</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8">
        <v-text-field
          v-model="nameSupplier"
          outlined
          dense
          hide-details
          label="กรอกชื่อซัพพลายเออร์"
          placeholder="ชื่อซัพพลายเออร์"
        ></v-text-field>
      </v-col>
      <!-- ที่อยู่ -->
      <v-col cols="12" sm="6" md="4">
        <h4 class="text-end">ที่อยู่</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8">
        <v-textarea outlined hide-details label="เพิ่มที่อยู่" :value="address"></v-textarea>
      </v-col>
      <!-- เลขประจำตัวผู้เสียภาษี -->
      <v-col cols="12" sm="6" md="4">
        <h4 class="text-end">เลขประจำตัวผู้เสียภาษี</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8">
        <v-text-field
          v-model="numberTax"
          outlined
          dense
          hide-details
          label="กรอกเลขประจำตัวผู้เสียภาษี"
          placeholder="เลขประจำตัวผู้เสียภาษี"
        ></v-text-field>
      </v-col>
      <!-- สำนักงาน/สาขาเลขที่ -->
      <v-col cols="12" sm="6" md="4">
        <h4 class="text-end">สำนักงาน/สาขาเลขที่</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8">
        <p>สามารถเพิ่มสาขา และรายชื่อผู้ติดต่อได้โดยกดด้านล่าง</p>
        <v-text-field
          v-model="branch"
          outlined
          dense
          hide-details
          label="สำนักงาน"
          placeholder="สำนักงาน"
        ></v-text-field>
      </v-col>
      <!-- ข้อมูลผู้ติดต่อ -->
      <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0">
        <h4 class="text-end">ข้อมูลผู้ติดต่อ</h4>
      </v-col>
      <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">
        <p v-if="!showData" @click="clickShowData" class="mb-0 pTagDynamic">แสดงข้อมูล</p>
        <p v-if="showData" @click="clickShowData" class="mb-0 pTagDynamic">ซ่อนข้อมูล</p>
        <div v-if="showData">
          <v-text-field
            v-model="contactPerson"
            outlined
            dense
            hide-details
            label="ผู้ติดต่อ"
            placeholder="กรอกผู้ติดต่อ"
            class="mt-2 mb-3"
          ></v-text-field>
          <v-text-field
            v-model="emailPerson"
            outlined
            dense
            hide-details
            label="E-mail"
            placeholder="กรอก Email"
            class="mt-2 mb-3"
          ></v-text-field>
          <v-text-field
            v-model="telPerson"
            outlined
            dense
            hide-details
            label="โทรศัพท์"
            placeholder="กรอกเบอร์โทรศัพท์"
            class="mt-2 mb-3"
          ></v-text-field>
        </div>
      </v-col>
      <!-- จัดการสาขา -->
      <div class="mt-5 fix-position">
        <v-dialog transition="dialog-top-transition" width="700">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" class="me-2">
              จัดการสาขา
            </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary">
                <p class="mb-0">จัดการสาขา และผู้ติดต่อ</p>
              </v-toolbar>
              <ManageBranch />
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </v-row>
    <!-- end content supplier -->

    <hr class="mb-3 mt-7" />

    <!-- content if and alret -->
    <h3>เงื่อนไขราคา และการแจ้งเตือน</h3>
    <p class="mb-0">การตั้งค่าเงื่อนไขราคา ระบบแจ้งเตือนการวางบิล และรับเช็ค</p>
    <hr class="mt-3 mb-3" />
    <v-row>
      <!-- สกุลเงิน -->
      <v-col md="4" sm="6" cols="12">
        <h4 class="text-end">สกุลเงิน</h4>
      </v-col>
      <v-col md="8" sm="6" cols="12">
        <v-select :items="currency" label="สกุลเงิน" outlined hide-details></v-select>
      </v-col>

      <!-- เครดิต -->
      <v-col md="4" sm="6" cols="12">
        <h4 class="text-end">เครดิต</h4>
      </v-col>
      <v-col md="8" sm="6" cols="12">
        <v-select :items="credit" label="เครดิต" outlined hide-details></v-select>
      </v-col>

      <!-- วันที่รับวางบิล -->
      <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0">
        <h4 class="text-end">วันที่รับวางบิล</h4>
      </v-col>
      <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">
        <v-radio-group v-model="radioGroup" hide-details>
          <v-radio v-for="n in dateBill" :key="n" :label="`${n}`" :value="n"></v-radio>
        </v-radio-group>
      </v-col>

      <!-- วันที่จ่ายเงิน -->
      <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 mb-0">
        <h4 class="text-end">วันที่ไปจ่ายเงิน</h4>
      </v-col>
      <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">
        <v-radio-group v-model="radioGroup" hide-details>
          <v-radio v-for="n in paymantDate" :key="n" :label="`${n}`" :value="n"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <!-- end content if and alret -->

    <hr class="mb-3 mt-7" />

    <!-- บัญชีรับเงิน -->
    <h3>บัญชีรับเงินของซัพพลายเออร์</h3>
    <p>ข้อมูลบัญชีรับเงินของซัพพลายเออร์</p>
    <Table />
    <!-- จบบัญชีรับเงิน -->

    <hr class="mb-5 mt-5" />

    <!-- เอกสาร -->
    <h3>เอกสารแนบและโน้ต</h3>
    <p>โหลดหนังสือรับรอง หรือ ภพ.20 หรือเอกสารโพรไฟล์อื่น ๆ</p>
    <v-row class="mt-3">
      <!-- โน้ต -->
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1">
        <h4 class="text-end">โน้ต</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-1">
        <v-textarea outlined hide-details label="โน้ต" :value="note"></v-textarea>
      </v-col>

      <!-- บัตรประชาชน -->
      <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0 mt-3">
        <h4 class="text-end">บัตรประชาชน</h4>
      </v-col>
      <v-col md="8" sm="6" cols="12" class="pt-0 pb-0 mt-3">
        <v-btn color="primary" class="text-none" round depressed :loading="isSelecting_button3" @click="onButtonClick3">
          <v-icon left>
            {{ icons.mdiCloudUpload }}
          </v-icon>
          {{ buttonText }}
        </v-btn>
        <input ref="uploader" class="d-none" type="file" @change="onFileChanged3" />
      </v-col>

      <!-- เอกสารอื่นๆ -->
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1 mt-3">
        <h4 class="text-end">เอกสารอื่น ๆ</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-0 mt-3">
        <v-btn color="primary" class="text-none" round depressed :loading="isSelecting_button4" @click="onButtonClick4">
          <v-icon left>
            {{ icons.mdiCloudUpload }}
          </v-icon>
          {{ buttonText }}
        </v-btn>
        <input ref="uploader" class="d-none" type="file" @change="onFileChanged4" />
      </v-col>
    </v-row>
    <!-- จบเอกสาร -->

    <hr class="mt-8 mb-5" />
    <!-- ผังบัญชีเชื่อมโยง -->
    <h3>ผังบัญชีเชื่อมโยง</h3>
    <p>การบันทึกบัญชีเชื่อมโยงเข้ากับผังบัญชีที่กำหนดไว้</p>
    <v-row>
      <!-- บัญชีเจ้าหนี้การค้า -->
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1 mt-3">
        <h4>บัญชีเจ้าหนี้การค้า</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-1 mt-3">
        <p v-if="!showdataAccountsPayable" class="mb-0 pTagDynamic" @click="clickShowPayble()">โปรดเลือก</p>
        <p v-if="showdataAccountsPayable" class="mb-2 pTagDynamic" @click="clickShowPayble()">ซ่อน</p>
        <v-select v-if="showdataAccountsPayable" :items="dataPayble" label="โปรดเลือก" outlined></v-select>
      </v-col>
      <!-- บัญชีเจ้าหนี้การค้าต่างประเทศ -->
      <v-col cols="12" sm="6" md="4" class="pt-0 pb-1 mt-3">
        <h4>บัญชีเจ้าหนี้การค้าต่างประเทศ</h4>
      </v-col>
      <v-col cols="12" sm="6" md="8" class="pt-0 pb-1 mt-3">
        <p v-if="!showdataForeign" class="mb-0 pTagDynamic" @click="clickShowForeign()">โปรดเลือก</p>
        <p v-if="showdataForeign" class="mb-2 pTagDynamic" @click="clickShowForeign()">ซ่อน</p>
        <v-select v-if="showdataForeign" :items="dataForeign" label="โปรดเลือก" outlined></v-select>
      </v-col>
    </v-row>
    <!-- จบผังบัญชีเชื่อมโยง -->
  </v-card-text>
</template>

<script>
import { mdiMagnify, mdiCloudUpload } from '@mdi/js'
import RevenueDepartment from '../../page-createBill/RevenueDepartment.vue'
import ManageBranch from './ManageBranch.vue'
import Table from './tableSupplier.vue'

export default {
  components: { RevenueDepartment, ManageBranch, Table },
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiCloudUpload,
      },
    }
  },
  data() {
    return {
      mockReceipt: 'RI-00000002',
      mockDayReceipt: '01/01/2022',
      address: '',
      numberTax: '',
      branch: 'สำนักงานใหญ่',
      hideData: true,
      desserts: [
        {
          nameProduct: 'Frozen Yogurt',
          quantity: 159,
          priceUnit: 6.0,
          discount: 0,
          price: 24,
        },
        {
          nameProduct: 'Ice cream sandwich',
          quantity: 237,
          priceUnit: 9.0,
          discount: 0,
          price: 37,
        },
        {
          nameProduct: 'Eclair',
          quantity: 262,
          priceUnit: 16.0,
          discount: 0,
          price: 23,
        },
      ],
      headers: [
        {
          text: 'สินค้า',
          align: 'start',
          sortable: false,
          value: 'nameProduct',
        },
        { text: 'จำนวน', value: 'quantity' },
        { text: 'ราคาต่อหน่วย', value: 'priceUnit' },
        { text: 'ส่วนลด', value: 'discount' },
        { text: 'ราคารวม', value: 'price' },
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      isSelecting: false,
      isSelecting_button2: false,
      isSelecting_button3: false,
      isSelecting_button4: false,
      buttonText: 'ไฟล์แนบ',
      nameSupplier: '',
      showData: false,
      currency: ['เพิ่มสกุลเงิน'],
      credit: ['7วัน', '15วัน', '30วัน', '45วัน', '60วัน', '90วัน'],
      dateBill: ['รายสัปดาห์', 'รายเดือน', 'กำหนดวันที่แน่นอน'],
      paymantDate: ['รายสัปดาห์', 'รายเดือน', 'กำหนดวันที่แน่นอน'],
      note: '',
      showdataAccountsPayable: false,
      showdataForeign: false,
      dataPayble: ['เพิ่มข้อมูล'],
      dataForeign: ['เพิ่มข้อมูล'],
    }
  },
  methods: {
    switchHide() {
      this.hideData = !this.hideData
    },
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true },
      )

      this.$refs.uploader.click()
    },
    onButtonClick2() {
      this.isSelecting_button2 = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting_button2 = false
        },
        { once: true },
      )

      this.$refs.uploader.click()
    },
    onButtonClick3() {
      this.isSelecting_button3 = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting_button3 = false
        },
        { once: true },
      )

      this.$refs.uploader.click()
    },
    onButtonClick4() {
      this.isSelecting_button4 = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting_button4 = false
        },
        { once: true },
      )

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]

      // do something
    },
    onFileChanged2(e) {
      this.selectedFile = e.target.files[0]

      // do something
    },
    onFileChanged3(e) {
      this.selectedFile = e.target.files[0]

      // do something
    },
    onFileChanged4(e) {
      this.selectedFile = e.target.files[0]

      // do something
    },
    clickShowData() {
      this.showData = !this.showData
    },
    clickShowPayble() {
      this.showdataAccountsPayable = !this.showdataAccountsPayable
    },
    clickShowForeign() {
      this.showdataForeign = !this.showdataForeign
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
a {
  text-decoration: none;
}
.pTagDynamic {
  color: #8dbc8a;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.pTagDynamic:hover {
  color: #728f70;
}
.textCase {
  max-width: 200px;
}
.fix-position {
  margin: 0 auto;
}
</style>
