<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">
        สร้างรายชื่อ
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2">
        ตรวจสอบข้อมูล
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 3" step="3">
        สร้างรายชื่อในระบบ
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="4">
        เสร็จสิ้น
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <div class="mb-12">
          <div class="wapper_download_content">
            <h4 class="text-center mb-2">เตรียมข้อมูลรายชื่อซัพพลายเออร์</h4>
            <v-btn color="primary">
              <v-icon size="20" class="me-2">{{ icons.mdiDownload }}</v-icon>
              ดาวน์โหลดไฟล์ต้นฉบับ
            </v-btn>
          </div>
          <div class="mt-10 warpper_button">
            <p class="mb-2">เพิ่มไฟล์ (.xlsx)</p>
            <div class="in_content">
              <div class="d-flex align-center justify-center">
                <p class="mb-0 text-center me-4">ลากไฟล์ และวางที่นี่หรือ คลิดเพื่ออัปโหลดไฟล์</p>
                <v-btn color="primary" class="text-none" round depressed :loading="isSelecting" @click="onButtonClick">
                  <v-icon left>
                    {{ icons.mdiCloudUpload }}
                  </v-icon>
                  {{ buttonText }}
                </v-btn>
                <input ref="uploader" class="d-none" type="file" @change="onFileChanged" />
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-end">
          <v-btn color="primary" @click="e1 = 2">
            Continue
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="2">
        <div class="mb-12">
          <h4 class="text-center mb-3">กรุณาตรวจสอบความถูกต้องของข้อมูล</h4>
          <v-data-table :headers="headers" :items="desserts" class="elevation-1">
            <template v-slot:header.name="{ header }">
              {{ header.text.toUpperCase() }}
            </template>
          </v-data-table>
          <p class="mt-5 mb-0">ข้อมูลพนักงาน {{ desserts.length }} รายการจะถูกอัปโหลดเข้าระบบ</p>
        </div>

        <div class="d-flex justify-end">
          <v-btn color="primary" @click="e1 = 3">
            Continue
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="3">
        <div class="mb-12">
          <h4 class="mb-5 text-center">กำลังอัปโหลดข้อมูล กรุณาอย่าปิดหน้าต่างนี้</h4>
          <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
          <h4 class="mt-5 text-center">กำลังอัปโหลดข้อมูล...</h4>
        </div>
        <div class="d-flex justify-end">
          <v-btn color="primary" @click="e1 = 4">
            Continue
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="4">
        <div class="displaySuccess">
          <h4>ข้อมูลได้ทำการอัปโหลดสำเร็จแล้ว !!</h4>
          <v-icon color="primary" size="60" class="mt-5 icons">{{ icons.mdiCheckCircle }}</v-icon>
        </div>

        <div class="d-flex justify-end">
          <v-btn color="primary" @click="e1 = 1">
            Continue
          </v-btn>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { mdiDownload, mdiCloudUpload, mdiCheckCircle } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiDownload,
        mdiCloudUpload,
        mdiCheckCircle,
      },
    }
  },
  data() {
    return {
      e1: 1,
      isSelecting: false,
      buttonText: 'อัปโหลด',
      headers: [
        {
          text: 'เลขที่ใบรับสินค้า',
          align: 'start',
          value: 'numberBill',
        },
        { text: 'วันที่', value: 'day' },
        { text: 'ชื่อ', value: 'name' },
        { text: 'เลขผู้เสียภาษี', value: 'numberTax' },
        { text: 'ชื่อสินค้า', value: 'nameProduct' },
        { text: 'ชนิดสินค้า', value: 'typeProduct' },
        { text: 'จำนวน', value: 'amount' },
        { text: 'ราคาต่อหน่วย', value: 'priceUnit' },
        { text: 'อัตราภาษี', value: 'tax' },
      ],
      desserts: [
        {
          numberBill: 'RI-00000001',
          day: '01/03/2022',
          name: 'บจก. A จำกัด',
          numberTax: '01909019999999',
          nameProduct: 'สีทาบ้าน TOT',
          typeProduct: 'สินค้าขายนับสต๊อก',
          amount: 10,
          priceUnit: 900,
          tax: 'ไม่มีภาษี',
        },
        {
          numberBill: 'RI-00000001',
          day: '01/03/2022',
          name: 'บจก. A จำกัด',
          numberTax: '01909019999999',
          nameProduct: 'จ้างเหมาต่อเติมห้องครัว',
          typeProduct: 'งานบริการ',
          amount: 1,
          priceUnit: 20000,
          tax: 'ไม่มีภาษี',
        },
        {
          numberBill: 'RI-00000002',
          day: '01/03/2022',
          name: 'บจก. B จำกัด',
          numberTax: '01909019909471',
          nameProduct: 'กระเบื้องเซรามิก',
          typeProduct: 'สินค้าขายนับสต๊อก',
          amount: 1000,
          priceUnit: 80,
          tax: 'แยกภาษี',
        },
        {
          numberBill: 'RI-00000002',
          day: '01/03/2022',
          name: 'บจก. B จำกัด',
          numberTax: '01909019909471',
          nameProduct: 'พื้นไม้เทียม',
          typeProduct: 'สินค้าขายนับสต๊อก',
          amount: 50,
          priceUnit: 1200,
          tax: 'แยกภาษี',
        },
      ],
    }
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true },
      )

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]

      // do something
    },
  },
}
</script>

<style scoped>
.wapper_download_content {
  display: grid;
  justify-content: center;
}
.warpper_button {
  background-color: #f4f5f7;
  padding: 10px 20px 35px 20px;
}
.in_content {
  background-color: #ffffff;
  border: 1px dashed #dddddd;
  padding: 10px 20px;
}
.displaySuccess {
  display: grid;
  justify-content: center;
  align-content: center;
}
.icons {
  left: 50%;
  transform: translateX(-50%);
}
</style>
