<template>
  <v-card-text>
    <v-row>
      <v-col sm="6" md="6" cols="12">
        <div class="d-flex justify-space-between align-center mt-3">
          <v-text-field
            v-model="search"
            outlined
            dense
            hide-details
            label="ค้นหา"
            placeholder="ค้นหา"
            class="mt-2 mb-3"
          ></v-text-field>
          <v-btn color="primary ms-10">เพิ่มสาขา</v-btn>
        </div>
        <hr class="mt-3 mb-3" />
        <div class="d-flex justify-space-between mb-3 header-background">
          <h4>เมนูลัด</h4>
          <p class="mb-0 pTagDynamic">เลือกคอลัมน์ทั้งหมด</p>
        </div>
        <li v-for="(item, index) in branchs">{{ item.branch }} {{ item.value }}</li>
      </v-col>
      <v-col sm="6" md="6" cols="12" class="background-right">
        <div class="mt-3">
          <h4>ใส่รายละเอียด</h4>
          <v-text-field
            v-model="search"
            outlined
            dense
            hide-details
            label="ค้นหา"
            placeholder="ค้นหา"
            class="mt-2 mb-3"
          ></v-text-field>
          <v-textarea outlined hide-details label="เพิ่มที่อยู่" :value="address"></v-textarea>
          <v-text-field
            v-model="contactPerson"
            outlined
            dense
            hide-details
            label="ผู้ติดต่อ"
            placeholder="กรอกผู้ติดต่อ"
            class="mt-2 mb-3"
          ></v-text-field>
          <v-text-field
            v-model="emailPerson"
            outlined
            dense
            hide-details
            label="E-mail"
            placeholder="กรอก Email"
            class="mt-2 mb-3"
          ></v-text-field>
          <v-text-field
            v-model="telPerson"
            outlined
            dense
            hide-details
            label="โทรศัพท์"
            placeholder="กรอกเบอร์โทรศัพท์"
            class="mt-2 mb-3"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      branchs: [
        { branch: 'สำนักงานใหญ่', value: '(ค่าเริ่มต้น)' },
        { branch: 'สำนักงานใหม่', value: '' },
      ],
      address: '',
      contactPerson: '',
      emailPerson: '',
      telPerson: '',
    }
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
li {
  list-style: none;
}
.pTagDynamic {
  color: #8dbc8a;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.pTagDynamic:hover {
  color: #728f70;
}
.header-background {
  padding: 10px 10px;
  background-color: #f3f3f3;
  border-radius: 5px;
}
.background-right {
  background-color: #e9ebee;
}
</style>
